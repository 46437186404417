import Alpine from 'alpinejs';
// import intersect from '@alpinejs/intersect';
import Tooltip from "@ryangjchandler/alpine-tooltip";

import focus from '@alpinejs/focus';
Alpine.plugin(focus)


Alpine.plugin(Tooltip);

window.Alpine = Alpine
Alpine.start();
// Alpine.plugin(intersect);

import './smoothscroll';

document.addEventListener('DOMContentLoaded', function() {


});
